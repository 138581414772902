<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { IconName } from './Icon.vue';

// Imports

const search = defineModel<string>();

const { t } = useI18n();

// Props & Emits & Refs
withDefaults(
  defineProps<{
    type?: string;
    placeholder?: string;
    rightIcon?: IconName;
  }>(),
  {
    type: 'text',
  },
);
</script>

<template>
  <div class="w-full relative h-full">
    <input
      ref="input"
      v-model="search"
      aria-autocomplete="both"
      autocomplete="off"
      class="p-[12px] h-[32px] text-sm w-full autofill:bg-white bg-white outline-none focus:ring-[2px] ring-offset-0 border-[1px] min-w-[300px] text-[14px] text-gray-700 rounded-md"
      :placeholder="placeholder ?? t('global.search')"
      :type="type"
    />
    <ui-icon name="Search" class="stroke-gray-400 absolute w-[18px] top-1/2 -translate-y-1/2 right-[12px]" />
  </div>
</template>
